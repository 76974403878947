import { Icon, Button } from "@/components";
import { Teacher } from "@/types/teacher";
import "./styles.scss";

export const TeacherCard = ({ teacher }: { teacher: Teacher }) => {
  return (
    <div className="teacher_card--container">
      <div className="top">
        <Icon name="play" />
      </div>
      <div className="main">
        <div className="profile">
          <div className="avatar-container">
            <img src={teacher.avatar} alt="avatar" />
          </div>
          <div className="profile-right">
            <h1>
              {teacher.firstname} {teacher.lastname}
            </h1>
            <h3>{teacher.role}</h3>
            <div className="interests">
              {teacher.interests.map((interest) => (
                <div className="interest" key={interest.id}>
                  {interest.value}
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="languages-container">
          <div className="icon-container">
            <Icon name="chalkboard" />
          </div>
          <div className="languages">
            {teacher.languages.map((language) => (
              <div className="language">
                <span className="language-name">{language.name}</span>
                <span className="language-proficiency">
                  {language.proficiency}
                </span>
              </div>
            ))}
          </div>
        </div>
        <div className="speaks-container">
          <div className="icon-container">speaks:</div>
          <div className="languages">
            {teacher.languages.map((language) => (
              <div className="language">
                <span className="language-name">{language.name}</span>
              </div>
            ))}
          </div>
        </div>
        <div className="interests-container">
          <div className="icon-container">interests:</div>
          <div className="languages">
            {teacher.interests.map((interest) => (
              <div className="language">
                <span className="language-name">{interest.value}</span>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="bottom">
        <Button variant="neutral" className="msg-btn">
          Message
        </Button>
        <Button variant="neutral" className="send-btn">
          Book
        </Button>
      </div>
    </div>
  );
};
