import { NavLink } from "react-router-dom";
import DashboardLayout from "@/layouts/dashboard-layout";
import {
  InteractiveLessonCard,
  DashBoardTeacherCard,
  ScheduleCard,
} from "./interactive-lesson-card";

export const Home = () => {
  return (
    <DashboardLayout>
      <section className="home-section">
        <section>
          <InteractiveLessonCard />
          <div className="space"></div>

          <DashBoardTeacherCard
            title="Your Igbo Language Partners are Waiting."
            buttoText="Find A Partner"
          />
          <div className="space"></div>
          <DashBoardTeacherCard
            title="Your Igbo Language Teachers Are Waiting"
            buttoText="Find A Teacher"
          />
        </section>
        <section>
          <div className="space"></div>
          <NavLink to="/schedule">
            <ScheduleCard />
          </NavLink>
        </section>
      </section>
    </DashboardLayout>
  );
};
