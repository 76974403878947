import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";
import { POST } from "@/services/api-request";
import { toast } from "sonner";
import { NavigateFunction } from "react-router-dom";

interface ReportPayload {
  token: string;
  bookingId: string;
  marchantId: string;
  problem: string;
  description: string;
  navigate: NavigateFunction;
}

interface ReportResponse {
  data: object;
  message?: string;
}

interface ReportState {
  data: ReportResponse | null;
  loading: boolean;
  error: string | null;
}

const initialState: ReportState = {
  data: null,
  loading: false,
  error: null,
};

export const reportFn = createAsyncThunk<ReportResponse, ReportPayload>(
  "report/reportTeacher",
  async (payload, { rejectWithValue }) => {
    const { token, bookingId, marchantId, problem, description, navigate } =
      payload;

    try {
      const { response } = await POST(
        `/teachers/report`,
        {
          bookingId,
          marchantId,
          problem,
          description,
        },
        token
      );
      navigate(-1);
      toast.success("Report submitted successfully");
      return response;
    } catch (err: any) {
      toast.error(err.response?.data?.message || "An unknown error occurred");

      return rejectWithValue(
        err.response?.data?.message || "An unknown error occurred"
      );
    }
  }
);

const reportSlice = createSlice({
  name: "report",
  initialState,
  reducers: {
    resetReportState: (state) => {
      state.data = null;
      state.loading = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(reportFn.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        reportFn.fulfilled,
        (state, action: PayloadAction<ReportResponse>) => {
          state.data = action.payload;
          state.loading = false;
          state.error = null;
        }
      )
      .addCase(reportFn.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload || "Failed to report teacher";
      });
  },
});

export const { resetReportState } = reportSlice.actions;

export default reportSlice.reducer;
