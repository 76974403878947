import { Activity } from "@/types/activity";

export const activities: Activity[] = [
  {
    id: "1",
    title: "Level A1 (Beginner) ",
    level: "A1",
    description:
      "At this level, learners focus on fundamental vocabulary, basic grammar structures, and essential communication skills.",
    image: "/activity.png",
    lessons: [
      {
        activityType: "SelectCorrectImageActivity",
        instruction: "Select the correct Image",
        question: {
          audioUrl:
            "https://lams-assets.s3.us-east-1.amazonaws.com/audio/000C.mp3",
        },
        answer: "0001",
        options: [
          {
            id: "0001",
            imageUrl:
              "https://lams-assets.s3.us-east-1.amazonaws.com/graphics/0002.png",
            audioUrl:
              "https://lams-assets.s3.us-east-1.amazonaws.com/audio/000C.mp3",
            text: "Nwoke",
          },
          {
            id: "0002",
            imageUrl:
              "https://lams-assets.s3.us-east-1.amazonaws.com/graphics/0001.svg",
            audioUrl:
              "https://lams-assets.s3.us-east-1.amazonaws.com/audio/1001.m4a",
            text: "Nwanyi",
          },
          {
            id: "0003",
            imageUrl:
              "https://lams-assets.s3.us-east-1.amazonaws.com/graphics/0004.png",
            audioUrl:
              "https://lams-assets.s3.us-east-1.amazonaws.com/audio/100F.m4a",
            text: "Ngaji",
          },
          {
            id: "0004",
            imageUrl:
              "https://lams-assets.s3.us-east-1.amazonaws.com/graphics/0000.svg",
            audioUrl:
              "https://lams-assets.s3.us-east-1.amazonaws.com/audio/100D.m4a",
            text: "Efere",
          },
        ],
      },
      {
        activityType: "FillTheGapActivity",
        instruction: "Fill in the Gap",
        question: "Nwoke _ Nwanyi",
        answer: ["0007"],
        options: [
          {
            id: "0008",
            audioUrl:
              "https://lams-assets.s3.us-east-1.amazonaws.com/audio/100D.m4a",
            text: "Efere",
          },
          {
            id: "0009",
            audioUrl:
              "https://lams-assets.s3.us-east-1.amazonaws.com/audio/100F.m4a",
            text: "Ngaji",
          },
          {
            id: "0007",
            audioUrl:
              "https://lams-assets.s3.us-east-1.amazonaws.com/audio/0003.mp3",
            text: "na",
          },
        ],
      },
      {
        activityType: "TapInWhatYouHear",
        instruction: "Tap in What you hear",
        question: {
          audioUrl:
            "https://lams-assets.s3.us-east-1.amazonaws.com/audio/000D.mp3",
        },
        answer: ["0005", "0007", "0006"],
        options: [
          {
            id: "0005",
            audioUrl:
              "https://lams-assets.s3.us-east-1.amazonaws.com/audio/000C.mp3",
            text: "Nwoke",
          },
          {
            id: "0006",
            audioUrl:
              "https://lams-assets.s3.us-east-1.amazonaws.com/audio/1001.m4a",
            text: "Nwanyi",
          },
          {
            id: "0008",
            audioUrl:
              "https://lams-assets.s3.us-east-1.amazonaws.com/audio/100D.m4a",
            text: "Efere",
          },
          {
            id: "0007",
            audioUrl:
              "https://lams-assets.s3.us-east-1.amazonaws.com/audio/0003.mp3",
            text: "na",
          },
          {
            id: "0009",
            audioUrl:
              "https://lams-assets.s3.us-east-1.amazonaws.com/audio/100F.m4a",
            text: "Ngaji",
          },
        ],
      },
      {
        activityType: "CrossWordActivity",
        instruction: "Match the right options",
        question: [
          {
            text: "Male",
          },
          {
            text: "and",
          },
          {
            text: "female",
          },
        ],
        answer: ["0005", "0007", "0006"],
        options: [
          {
            id: "0005",
            audioUrl:
              "https://lams-assets.s3.us-east-1.amazonaws.com/audio/000C.mp3",
            text: "Nwoke",
          },
          {
            id: "0006",
            audioUrl:
              "https://lams-assets.s3.us-east-1.amazonaws.com/audio/1001.m4a",
            text: "Nwanyi",
          },
          {
            id: "0007",
            audioUrl:
              "https://lams-assets.s3.us-east-1.amazonaws.com/audio/0003.mp3",
            text: "na",
          },
        ],
      },
      {
        activityType: "MatchTheCorrectWord",
        instruction: "Select the right word",
        question: {
          text: "Welcome",
        },
        answer: ["0010"],
        options: [
          {
            id: "0001",
            imageUrl:
              "https://lams-assets.s3.us-east-1.amazonaws.com/graphics/0002.png",
            audioUrl:
              "https://lams-assets.s3.us-east-1.amazonaws.com/audio/000C.mp3",
            text: "Nwoke",
          },
          {
            id: "0010",
            audioUrl:
              "https://lams-assets.s3.us-east-1.amazonaws.com/audio/010A.mp3",
            text: "Nnonu",
          },
          {
            id: "0007",
            audioUrl:
              "https://lams-assets.s3.us-east-1.amazonaws.com/audio/0003.mp3",
            text: "na",
          },
          {
            id: "0003",
            imageUrl:
              "https://lams-assets.s3.us-east-1.amazonaws.com/graphics/0004.png",
            audioUrl:
              "https://lams-assets.s3.us-east-1.amazonaws.com/audio/100F.m4a",
            text: "Ngaji",
          },
        ],
      },
    ],
  },
];
