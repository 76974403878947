import Button from "@/components/Button";
import { SpeakAndSelectLessonQuestion } from "@/types/activity";
import { useEffect, useMemo, useRef, useState } from "react";
import { Verdict } from "../../Verdict";
import Icon from "@/components/Icon";
import "./styles.scss";

export const SelectWhatYouHearQuestion = ({
  question,
  handleNextQuestion,
}: {
  question: SpeakAndSelectLessonQuestion;
  handleNextQuestion: () => void;
}) => {
  const answer = question.answer;
  const [selectedOptionId, setSelectedOptionId] = useState<string | null>(null);
  const [isAudioPlaying, setIsAudioPlaying] = useState(false);
  const [canCheck, setCanCheck] = useState(false);
  const audioRef = useRef<HTMLAudioElement>(null);
  const answerOption = useMemo(
    () => question.options.find((o) => question.answer.includes(o.id)),
    [question]
  );

  const playAudio = () => {
    if (isAudioPlaying) {
      audioRef.current?.pause();
      audioRef.current!.currentTime = 0;
    } else audioRef.current?.play();
  };

  const handleClickOption = (optionId: string) => {
    setCanCheck(false);
    setSelectedOptionId((prev) => (prev === optionId ? null : optionId));
  };

  const handleCheck = () => setCanCheck(true);
  return (
    <div className="question_container" data-type={question.activityType}>
      <div className="top">
        <h1>{question.instruction}</h1>
        <div className="language_container">
          <Icon name="language" />
        </div>
      </div>
      <div className="middle">
        <Button
          variant="neutral"
          className="audio_container"
          onClick={playAudio}
        >
          <Icon name="audio" />
          <audio
            src={question.question.audioUrl}
            ref={audioRef}
            onPlay={() => setIsAudioPlaying(true)}
            onPause={() => setIsAudioPlaying(false)}
          ></audio>
        </Button>
        <Button variant="neutral" className="cantlisten">
          CAN'T LISTEN NOW
        </Button>
      </div>
      <div className="options">
        {question.options.map((option) => (
          <SelectWhatYouHearOption
            onClick={handleClickOption}
            canCheck={canCheck}
            option={option}
            selectedOptionId={selectedOptionId}
            key={option.id}
            is_answer={option.id === answer}
          />
        ))}
      </div>

      <Button
        disabled={selectedOptionId === null}
        variant="primary"
        className="check-btn"
        onClick={handleCheck}
      >
        Check
      </Button>
      <Verdict
        is_answer={answerOption?.id === selectedOptionId}
        answer={answerOption?.text ?? "No answer"}
        open={canCheck}
        onClose={() => setCanCheck(false)}
        handleNext={handleNextQuestion}
      />
    </div>
  );
};

const SelectWhatYouHearOption = ({
  option,
  selectedOptionId,
  canCheck,
  is_answer,
  onClick,
}: {
  option: SpeakAndSelectLessonQuestion["options"][number];
  canCheck: boolean;
  is_answer: boolean;
  selectedOptionId: string | null;
  onClick: (option_id: string) => void;
}) => {
  const audioRef = useRef<HTMLAudioElement>(null);
  const [isAudioPlaying, setIsAudioPlaying] = useState(false);

  const playAudio = () => {
    audioRef.current?.play();
  };

  const pauseAudio = () => {
    if (isAudioPlaying) {
      audioRef.current?.pause();
      audioRef.current!.currentTime = 0;
    }
  };

  useEffect(() => {
    if (selectedOptionId === option.id) playAudio();
    else pauseAudio();
  }, [selectedOptionId, option]);

  return (
    <Button
      variant="neutral"
      key={option.id}
      className="option"
      data-selected={String(option.id === selectedOptionId)}
      data-correct={String(
        canCheck && option.id === selectedOptionId && is_answer
      )}
      data-wrong={String(
        canCheck && option.id === selectedOptionId && !is_answer
      )}
      onClick={() => {
        onClick(option.id);
      }}
    >
      <img src={option.imageUrl} alt="option" />
      <p>{option.text}</p>
      <audio
        src={option.audioUrl}
        ref={audioRef}
        onPlay={() => setIsAudioPlaying(true)}
        onPause={() => setIsAudioPlaying(false)}
      ></audio>
    </Button>
  );
};
