import { Button, Icon, PasswordInput } from "@/components";
import "./styles.scss";

export default function PasswordSettings({ onClose }: { onClose: () => void }) {
  return (
    <div className="password-settings">
      <Button variant="neutral" className="back-btn" onClick={onClose}>
        <Icon name="left_arrow" />
        <span>Back</span>
      </Button>

      <h1 className="heading">Password Settings</h1>

      <div className="inputs">
        <PasswordInput placeholder="password" label={"Old Password"} />
        <PasswordInput placeholder="password" label={"New Password"} />
        <PasswordInput placeholder="password" label={"Confirm New Password"} />

        <div className="continue_btn--container">
          <Button variant="neutral">Continue</Button>
        </div>
      </div>
    </div>
  );
}
