import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";
import { PATCH } from "@/services/api-request";
import { toast } from "sonner";
import { NavigateFunction } from "react-router-dom";

interface ReviewValues {
  rating: number;
  review: string;
}

export interface ReviewParams {
  token: string;
  reviewValues: ReviewValues;
  bookingId: string;
  marchantId: string;
  setReviewValues: (values: ReviewValues) => void;
  goBack: NavigateFunction;
}

interface ReviewState {
  data: object | null;
  loading: boolean;
  error: string | null;
}

const initialState: ReviewState = {
  data: null,
  loading: false,
  error: null,
};

export const reviewUpdateFn = createAsyncThunk(
  "reviewTeacherUpdate",
  async (
    {
      token,
      reviewValues,
      bookingId,
      marchantId,
      setReviewValues,
      goBack,
    }: ReviewParams,
    { rejectWithValue }
  ) => {
    const raw = JSON.stringify({
      bookingId: bookingId,
      marchantId: marchantId,
      rating: reviewValues.rating,
      review: reviewValues.review,
    });

    try {
      const { response } = await PATCH(`/reviews/bookings`, raw, token);

      toast.success("Review updated successfully");

      goBack(-1);
      return response;
    } catch (err: any) {
      const errorMessage = err.response?.data?.message || "Unknown error";
      toast.error(errorMessage);
      console.warn("Error updating review:", errorMessage);
      return rejectWithValue(errorMessage);
    }
  }
);

const reviewUpdateSlice = createSlice({
  name: "reviewUpdate",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(reviewUpdateFn.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        reviewUpdateFn.fulfilled,
        (state, action: PayloadAction<object>) => {
          state.data = action.payload;
          state.loading = false;
          state.error = null;
        }
      )
      .addCase(reviewUpdateFn.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default reviewUpdateSlice.reducer;
