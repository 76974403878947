import { ILesson } from "@/types/schedules";

export const formatDateString = (date: Date): string => {
  const formatter = new Intl.DateTimeFormat("en-GB", {
    weekday: "long",
    day: "2-digit",
    month: "long",
    year: "numeric",
  });

  return formatter.format(date);
};

type TimeSlot = `${string}${"am" | "pm"}`;

export const formatTimeSlot = (time: Date): TimeSlot => {
  const h = time.getHours();
  const hour = (h === 12 ? 12 : h % 12).toString().padStart(2, "0");
  const minute = time.getMinutes().toString().padStart(2, "0");
  const meridian = h >= 12 ? "pm" : "am";
  return `${hour}:${minute}${meridian}`;
};

export function isLessonInProgress<T extends { time: ILesson["time_slot"] }>(
  lesson: T
): boolean {
  const { time } = lesson;

  const startTime = new Date(time.start);
  const endTime = new Date(time.end);
  const now = new Date();

  return now >= startTime && now <= endTime;
}

export function isLessonInPast<T extends { time: ILesson["time_slot"] }>(
  lesson: T
): boolean {
  const { time } = lesson;

  const endTime = new Date(time.end);

  const now = new Date();

  return now > endTime;
}

export const timeDifference = (targetDate: string): string => {
  const now: Date = new Date();
  const target: Date = new Date(targetDate);

  // Calculate the difference in milliseconds
  const differenceInMs: number = target.getTime() - now.getTime();

  // If the difference is negative or zero, the target date has passed or is now
  if (differenceInMs <= 0) {
    return "";
  }

  // Convert milliseconds to total seconds
  const differenceInSeconds: number = differenceInMs / 1000;

  // Calculate the remaining days, hours, minutes, and seconds
  const days: number = Math.floor(differenceInSeconds / 86400);
  const hours: number = Math.floor((differenceInSeconds % 86400) / 3600);
  const mins: number = Math.floor((differenceInSeconds % 3600) / 60);
  const secs: number = Math.floor(differenceInSeconds % 60);

  // Return formatted time
  if (days > 0) {
    return `${days} day(s)`;
  } else if (hours > 0) {
    return `${hours} hour(s) ${mins} min(s)`;
  } else if (mins > 0) {
    return `${mins} min(s) ${secs} sec(s)`;
  } else {
    return `${secs} sec(s)`;
  }
};

export const removeMultipleKeys = (keys: string[]): void => {
  keys.forEach((key) => {
    localStorage.removeItem(key);
  });
};

export function parseJSON<T>(value: string | null): T | null {
  try {
    return value ? (JSON.parse(value) as T) : null;
  } catch {
    return null;
  }
}

export const isMoreThan48HoursAgo = (time: Date | string): boolean => {
  const now = new Date();
  const givenTime = typeof time === "string" ? new Date(time) : time;
  const fortyEightHoursAgo = new Date(now.getTime() - 48 * 60 * 60 * 1000);

  return givenTime < fortyEightHoursAgo;
};
