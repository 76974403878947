import Icons from "./icons";
import { SVGProps } from "react";

export interface IconPropTypes extends SVGProps<SVGSVGElement> {
  color?: string;
  width?: number;
  height?: number;
  size?: number;
}

export default function Icon({
  name,
  ...props
}: IconPropTypes & { name: keyof typeof Icons }) {
  const Icon = Icons[name];
  const exportedIcon = <Icon {...props} />;
  return exportedIcon;
}
