import React, { useEffect, useState } from "react";
import DisplayLayout from "@/layouts/dashboard-layout";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Icon } from "@/components";
import {
  formatDateString,
  formatTimeSlot,
  isMoreThan48HoursAgo,
  timeDifference,
} from "@/utils/function";
import "./styles.scss";
import { useAppDispatch, useAppSelector } from "@/redux/hooks";
import { useAuth } from "@/contexts/auth-context";
import { scheduleDetailFetch } from "@/redux/slice/schedule";
import { ScheduleHeader } from "../schedule-header";

export const CompletedScheduleDetails = () => {
  const { lesson_id } = useParams();
  const { token } = useAuth();

  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { data, error, loading } = useAppSelector((state) => state.detail);

  const _detailHandler = () => {
    dispatch(scheduleDetailFetch({ token: token ?? "", id: lesson_id ?? "" }));
  };

  useEffect(() => {
    _detailHandler();
  }, [lesson_id]);

  return (
    <DisplayLayout>
      <ScheduleHeader title="Schedule detail" />
      {loading ? (
        <p>Loading</p>
      ) : (
        data && (
          <div className="schedule-lesson">
            <div className="first-row">
              <div className="date">
                {formatDateString(new Date(data.lessonDetails.time.start))}
              </div>
              <div className="time">
                <span>
                  {formatTimeSlot(new Date(data.lessonDetails.time.start))}
                </span>{" "}
                -{" "}
                <span>
                  {formatTimeSlot(new Date(data.lessonDetails.time.end))}
                </span>
              </div>
            </div>
            <div className="teacher">
              <h1 className="title">Teacher</h1>
            </div>
            <div className="profile">
              <div className="imageProfile">
                <img
                  className="dpImage"
                  alt=""
                  src={data.marchantDetails.dpUrl}
                />
                <p className="name">{`${data.userDetails.firstName} ${data.userDetails.lastName}`}</p>
              </div>
              <p className="message">Message</p>
            </div>
            <Button
              onClick={() =>
                navigate(`./review`, {
                  state: {
                    bookingId: data.bookingId,
                    marchantId: data.marchantDetails.marchantId,
                    fullName: `${data.userDetails.firstName} ${data.userDetails.lastName}`,
                    dpUrl: data.marchantDetails.dpUrl,
                  },
                })
              }
              variant="secondary"
            >
              Review
            </Button>

            <div className="lesson">
              <h1 className="title">Lesson Package</h1>
              <h3 className="lesson--title">
                {data.lessonDetails.lessonTitle}
              </h3>
              <p className="description">{data.lessonDetails.description}</p>
            </div>
            <div className="classroom">
              <div className="column">
                <h1 className="title">Classroom</h1>
                <div className="bottom">
                  <Icon name="zoom" />
                  <p>{data.lessonDetails.venue.platform} Classroom</p>
                </div>
              </div>
              <div className="column">
                <h1 className="title">Classroom ID</h1>
                <div className="bottom">
                  <p>{data.lessonDetails.venue.meetingId}</p>
                  <Icon name="copy" />
                </div>
              </div>
              <div className="column">
                <h1 className="title">Classroom Passcode</h1>
                <div className="bottom">
                  <p>{data.lessonDetails.venue.passcode}</p>
                  <Icon name="copy" />
                </div>
              </div>
            </div>
            {/* {isMoreThan48HoursAgo(data?.lessonDetails?.time?.end) ||
            loading ? null : ( */}
            <div className="btnContainer">
              <Button
                variant="primary"
                className="action"
                // isLoading={isPending}
                // disabled={isPending || lesson.lessonDetails.isCompleted}
                onClick={() =>
                  navigate(`./dispute`, {
                    state: {
                      bookingId: data.bookingId,
                      marchantId: data.marchantDetails.marchantId,
                    },
                  })
                }
              >
                Dispute
              </Button>
              <Button
                variant="secondary"
                className="report"
                // isLoading={isPending}
                // disabled={isPending || lesson.lessonDetails.isCompleted}
                onClick={() =>
                  navigate(`./report`, {
                    state: {
                      bookingId: data.bookingId,
                      marchantId: data.marchantDetails.marchantId,
                    },
                  })
                }
              >
                Report
              </Button>
            </div>
            {/* )} */}
          </div>
        )
      )}
    </DisplayLayout>
  );
};
