import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { PATCH } from "../../../services/api-request";
import { toast } from "sonner";
import { NavigateFunction } from "react-router-dom";

interface MarkFnArgs {
  token: string;
  id: string;
  navigate: NavigateFunction;
}

interface MarkFnPayload {
  success: boolean;
  message: string;
  response: any;
}

interface MarkState {
  data: MarkFnPayload | null;
  loading: boolean;
  error: string | null;
}

const initialState: MarkState = {
  data: null,
  loading: false,
  error: null,
};

export const markFn = createAsyncThunk<
  MarkFnPayload,
  MarkFnArgs,
  { rejectValue: string }
>("markAsCompleted", async ({ token, id, navigate }, { rejectWithValue }) => {
  try {
    const response = await PATCH(`/bookings/mark-completed/${id}`, {}, token);
    navigate(-1);
    toast.success("Marked successfully");
    return response as MarkFnPayload;
  } catch (err: any) {
    return rejectWithValue(err.response?.data?.message || "Unknown error");
  }
});

const markSlice = createSlice({
  name: "mark",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(markFn.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        markFn.fulfilled,
        (state, action: PayloadAction<MarkFnPayload>) => {
          state.data = action.payload;
          state.loading = false;
          state.error = null;
        }
      )
      .addCase(markFn.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || "An error occurred.";
      });
  },
});

export default markSlice.reducer;
