import React, { useEffect, useState } from "react";
import DisplayLayout from "@/layouts/dashboard-layout";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Icon } from "@/components";

import {
  formatDateString,
  isLessonInPast,
  timeDifference,
} from "@/utils/function";
import "./styles.scss";
import { useAppDispatch, useAppSelector } from "@/redux/hooks";
import { markFn, scheduleDetailFetch } from "@/redux/slice/schedule";
import { useAuth } from "@/contexts/auth-context";
import { ScheduleHeader } from "../schedule-header";

export const ScheduleDetails = () => {
  const { lesson_id } = useParams();
  const [timeDiff, setTimeDiff] = useState<string>("");

  const { token } = useAuth();

  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const handleButtonClick = () => {
    dispatch(
      markFn({
        token: token ?? "",
        id: lesson_id ?? "",
        navigate: navigate,
      })
    );
  };

  const { data, error, loading } = useAppSelector((state) => state.detail);
  const { loading: markLoading } = useAppSelector((state) => state.mark);
  useEffect(() => {
    if (data) {
      const updateTimeDiff = () => {
        setTimeDiff(timeDifference(data?.lessonDetails?.time?.start));
      };

      updateTimeDiff();

      const intervalId = setInterval(updateTimeDiff, 1000);

      return () => clearInterval(intervalId);
    }
  }, []);

  useEffect(() => {
    if (data) {
      const updateTimeDiff = () => {
        setTimeDiff(timeDifference(data?.lessonDetails?.time?.start));
      };
      updateTimeDiff();
      const intervalId = setInterval(updateTimeDiff, 1000);

      return () => clearInterval(intervalId);
    }
  }, []);

  const _detailHandler = () => {
    dispatch(scheduleDetailFetch({ token: token ?? "", id: lesson_id ?? "" }));
  };

  useEffect(() => {
    _detailHandler();
  }, [lesson_id]);

  return (
    <DisplayLayout>
      <ScheduleHeader title="Schedule detail" />

      {loading ? (
        <p>Loading</p>
      ) : markLoading ? (
        <p>Marking</p>
      ) : (
        data && (
          <div className="schedule-lesson">
            <div className="first-row">
              <div className="time">
                Class Starts in <span className="timeDiff">{timeDiff}</span>
              </div>
            </div>
            <div className="date">
              {formatDateString(new Date(data.lessonDetails.time.start))}
            </div>
            <div className="teacher">
              <h1 className="title">Teacher</h1>
            </div>
            <div className="profile">
              <div className="imageProfile">
                <img
                  className="dpImage"
                  alt=""
                  src={data.marchantDetails.dpUrl}
                />
                <p className="name">{`${data.userDetails.firstName} ${data.userDetails.lastName}`}</p>
              </div>
              <p className="message">Message</p>
            </div>
            <div className="lesson">
              <h1 className="title">Lesson Package</h1>
              <h3 className="lesson--title">
                {data.lessonDetails.lessonTitle}
              </h3>
              <p className="description">{data.lessonDetails.description}</p>
            </div>
            <div className="classroom">
              <div className="column">
                <h1 className="title">Classroom</h1>
                <div className="bottom">
                  <Icon name="zoom" />
                  <p>{data.lessonDetails.venue.platform} Classroom</p>
                </div>
              </div>
            </div>
            <div className="btnContainer">
              <Button
                variant="primary"
                className="action"
                // isLoading={isPending}
                // disabled={isPending || lesson.lessonDetails.isCompleted}
                onClick={handleButtonClick}
              >
                {isLessonInPast(data.lessonDetails)
                  ? data.lessonDetails.isCompleted === false
                    ? "Mark as completed"
                    : "Completed"
                  : "Upcoming"}
              </Button>
            </div>
          </div>
        )
      )}
    </DisplayLayout>
  );
};
