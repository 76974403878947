import { Teacher } from "@/types/teacher";

export const teachers: Teacher[] = [
  {
    id: "871gko91",
    firstname: "Nonso",
    lastname: "Okafor",
    avatar: "/teacher_avatar.jpeg",
    interests: [
      {
        id: "1",
        value: "Film",
      },
      {
        id: "2",
        value: "Sports",
      },
      {
        id: "3",
        value: "music",
      },
    ],
    languages: [
      {
        id: "1",
        name: "igbo",
        proficiency: "expert",
      },
      {
        id: "2",
        name: "hausa",
        proficiency: "expert",
      },
      {
        id: "3",
        name: "Swahili",
        proficiency: "expert",
      },
    ],
    bio: "My Bio",
    role: "language expert",
  },
  {
    id: "9817jaby6",
    firstname: "Mulokwu",
    lastname: "Ekene",
    avatar: "/teacher_avatar.jpeg",
    interests: [
      {
        id: "1",
        value: "Film",
      },
      {
        id: "2",
        value: "Sports",
      },
      {
        id: "3",
        value: "music",
      },
    ],
    languages: [
      {
        id: "1",
        name: "igbo",
        proficiency: "expert",
      },
      {
        id: "2",
        name: "hausa",
        proficiency: "expert",
      },
      {
        id: "3",
        name: "Swahili",
        proficiency: "expert",
      },
    ],
    bio: "Hello, My name is Okonkwo Chioma. I am a nigerian and I live in Nigeria. I am a Language Teacher and I have been teaching for over 6 years. I have taught in different primary/secondary schools and language centers. I studied languages and linguistics. I am currently doing my Masters in french as a foreign language and also a TEFL course. I am also a DELF DALF examiner. I love teaching and look forward to helping people with their Igbo language and French language.",
    role: "language expert",
  },
];
