import { ILesson, ILessonDetails } from "@/types/schedules";
export const nav_items = [
  {
    name: "upcoming",
    label: "Upcoming",
  },
  {
    name: "completed",
    label: "Completed",
  },
];

export const upcomingLessons: ILesson[] = [
  {
    id: "lesson5",
    scheduled_date: "2024-11-20T00:00:00Z",
    time_slot: {
      start: "2024-11-20T14:00:00Z",
      end: "2024-11-20T15:30:00Z",
    },
    time: {
      start: "2024-11-20T14:00:00Z",
      end: "2024-11-20T15:30:00Z",
    },
    language: "English",
    title: "Node.js Fundamentals",
    description: "Understand the basics of Node.js and backend development.",
    classroom: {
      id: "class5",
      passcode: "7890",
      location: "Room 505",
    },
    student: {
      name: "Sara White",
      id: "student5",
    },
    isCompleted: false,
  },
  {
    id: "lesson6",
    scheduled_date: "2024-11-22T00:00:00Z",
    time_slot: {
      start: "2024-11-22T09:00:00Z",
      end: "2024-11-22T10:30:00Z",
    },
    time: {
      start: "2024-11-20T14:00:00Z",
      end: "2024-11-20T15:30:00Z",
    },
    language: "French",
    title: "CSS Animations",
    description: "Learn how to create engaging animations using CSS.",
    classroom: {
      id: "class6",
      passcode: "2345",
      location: "Room 606",
    },
    student: {
      name: "Tom Harris",
      id: "student6",
    },
    isCompleted: false,
  },
  {
    id: "lesson7",
    scheduled_date: "2024-11-25T00:00:00Z",
    time_slot: {
      start: "2024-11-25T16:00:00Z",
      end: "2024-11-25T17:30:00Z",
    },
    time: {
      start: "2024-11-20T14:00:00Z",
      end: "2024-11-20T15:30:00Z",
    },
    language: "Spanish",
    title: "React Native Navigation",
    description: "Explore navigation techniques in React Native applications.",
    classroom: {
      id: "class7",
      passcode: "4567",
      location: "Room 707",
    },
    student: {
      name: "Emma Wilson",
      id: "student7",
    },
    isCompleted: false,
  },
  {
    id: "lesson8",
    scheduled_date: "2024-11-28T00:00:00Z",
    time_slot: {
      start: "2024-11-28T11:00:00Z",
      end: "2024-11-28T12:30:00Z",
    },
    time: {
      start: "2024-11-20T14:00:00Z",
      end: "2024-11-20T15:30:00Z",
    },
    language: "German",
    title: "Introduction to TypeScript",
    description: "Get started with TypeScript and its powerful features.",
    classroom: {
      id: "class8",
      passcode: "6789",
      location: "Room 808",
    },
    student: {
      name: "Daniel Lee",
      id: "student8",
    },
    isCompleted: false,
  },
];

export const completedLessons: ILesson[] = [
  {
    id: "lesson1",
    scheduled_date: "2024-11-15T00:00:00Z",
    time_slot: {
      start: "2024-11-15T09:00:00Z",
      end: "2024-11-15T10:00:00Z",
    },
    time: {
      start: "2024-11-20T14:00:00Z",
      end: "2024-11-20T15:30:00Z",
    },
    language: "English",
    title: "Introduction to React",
    description: "Learn the basics of React and component structure.",
    classroom: {
      id: "class1",
      passcode: "1234",
      location: "Room 101",
    },
    student: {
      name: "John Doe",
      id: "student1",
    },
    isCompleted: true,
  },
  {
    id: "lesson2",
    scheduled_date: "2024-11-14T00:00:00Z",
    time_slot: {
      start: "2024-11-14T13:00:00Z",
      end: "2024-11-14T14:00:00Z",
    },
    time: {
      start: "2024-11-20T14:00:00Z",
      end: "2024-11-20T15:30:00Z",
    },
    language: "French",
    title: "Advanced Vue.js",
    description: "Delve deeper into Vue.js concepts and patterns.",
    classroom: {
      id: "class2",
      passcode: "5678",
      location: "Room 202",
    },
    student: {
      name: "Jane Smith",
      id: "student2",
    },
    isCompleted: true,
  },
  {
    id: "lesson3",
    scheduled_date: "2024-11-13T00:00:00Z",
    time_slot: {
      start: "2024-11-13T15:30:00Z",
      end: "2024-11-13T16:30:00Z",
    },
    time: {
      start: "2024-11-20T14:00:00Z",
      end: "2024-11-20T15:30:00Z",
    },
    language: "German",
    title: "State Management with Redux",
    description: "Master state management techniques with Redux.",
    classroom: {
      id: "class3",
      passcode: "9012",
      location: "Room 303",
    },
    student: {
      name: "Alice Brown",
      id: "student3",
    },
    isCompleted: true,
  },
  {
    id: "lesson4",
    scheduled_date: "2024-11-12T00:00:00Z",
    time_slot: {
      start: "2024-11-12T10:00:00Z",
      end: "2024-11-12T11:30:00Z",
    },
    time: {
      start: "2024-11-20T14:00:00Z",
      end: "2024-11-20T15:30:00Z",
    },
    language: "Spanish",
    title: "Functional Programming in JavaScript",
    description: "Explore functional programming concepts in JavaScript.",
    classroom: {
      id: "class4",
      passcode: "3456",
      location: "Room 404",
    },
    student: {
      name: "Michael Johnson",
      id: "student4",
    },
    isCompleted: true,
  },
];

export const lesson: ILessonDetails = {
  bookingId: "B123456789",
  languageId: "EN",
  marchantDetails: {
    marchantId: "M123456",
    dpUrl:
      "https://gratisography.com/wp-content/uploads/2024/10/gratisography-cool-cat-800x525.jpg",
    firstName: "John",
    lastName: "Doe",
    otherNames: "Smith",
  },
  userDetails: {
    userId: "U987654",
    firstName: "Alice",
    lastName: "Johnson",
    otherName: "Marie",
  },
  lessonDetails: {
    lessonId: "L001",
    lessonTitle: "Introduction to Programming",
    description:
      "Learn the basics of programming, including syntax and logic building.",
    time: {
      start: "2024-11-25T09:00:00Z",
      end: "2024-11-25T10:00:00Z",
    },
    venue: {
      platform: "Zoom",
      meetingUrl: "https://zoom.us/j/123456789",
      meetingId: "123456789",
      passcode: "abc123",
    },
    isCompleted: false,
  },
};

export const completedLesson: ILessonDetails = {
  bookingId: "B123456789",
  languageId: "EN",
  marchantDetails: {
    marchantId: "M123456",
    dpUrl:
      "https://gratisography.com/wp-content/uploads/2024/10/gratisography-cool-cat-800x525.jpg",
    firstName: "John",
    lastName: "Doe",
    otherNames: "Smith",
  },
  userDetails: {
    userId: "U987654",
    firstName: "Alice",
    lastName: "Johnson",
    otherName: "Marie",
  },
  lessonDetails: {
    lessonId: "L001",
    lessonTitle: "Introduction to Programming",
    description:
      "Learn the basics of programming, including syntax and logic building.",
    time: {
      start: "2024-11-30T09:00:00Z",
      end: "2024-11-30T10:00:00Z",
    },
    venue: {
      platform: "Zoom",
      meetingUrl: "https://zoom.us/j/123456789",
      meetingId: "123456789",
      passcode: "abc123",
    },
    isCompleted: true,
  },
};

export const disputeReasons = [
  {
    key: "Teacher didn't attend the lesson",
    value: "Teacher didn't attend the lesson",
  },
  {
    key: "Teacher was late to the lesson",
    value: "Teacher was late to the lesson",
  },
  {
    key: "Lesson didn't hold",
    value: "Lesson didn't hold",
  },
  {
    key: "Something else",
    value: "Something else",
  },
];

export const reportReasons = [
  { key: "Absent for the lesson", value: "Absent for the lesson" },
  { key: "Late to the lesson", value: "Late to the lesson" },
  { key: "Abuse or harassments", value: "Abuse or harassments" },
  { key: "Something Else", value: "Something Else" },
];
