import { Button, Icon } from "@/components";
import "./styles.scss";
import { ButtonProps } from "@/components/Button";
import { IconType } from "@/components/Icon/icons";

interface Props extends Omit<ButtonProps, "variant"> {
  title: string;
  icon: IconType;
}

export default function ProfileButton({
  className,
  title,
  icon,
  ...props
}: Props) {
  return (
    <Button variant="neutral" {...props} className={`profile-btn ${className}`}>
      <div className="icon-container">
        <Icon name={icon} />
      </div>
      <div className="right">
        <span>{title}</span>
        <Icon name="caret_right" />
      </div>
    </Button>
  );
}
