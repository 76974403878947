import "./styles.scss";

import { ReactNode, useEffect, useState } from "react";

interface Props {
  list: { name: string; label: string | ReactNode }[];
  defaultSelected: string;
  onSelectedChange?: (value: string) => void;
  className?: string;
}

export const TabSwitch = ({
  list,
  onSelectedChange,
  className,
  defaultSelected,
}: Props) => {
  const [selected, setSelected] = useState<string | null>(
    defaultSelected || null
  );
  useEffect(() => {
    if (!selected) return;
    onSelectedChange?.(selected);
  }, [selected, onSelectedChange]);

  const handleSelect = (name: string) => {
    setSelected(name);
  };
  return (
    <div className={`tab_switch--container ${className}`}>
      {list.map((item) => (
        <Tab
          key={item.name}
          name={item.name}
          label={item.label}
          isSelected={selected === item.name}
          onClick={() => handleSelect(item.name)}
        />
      ))}
    </div>
  );
};

export default function Tab({
  name,
  isSelected,
  className,
  onClick,
  label,
}: {
  className?: string;
  name: string;
  label?: string | ReactNode;
  isSelected: boolean;
  onClick?: () => void;
}) {
  return (
    <div
      className={`tab ${className}`}
      data-is-selected={isSelected}
      onClick={onClick}
    >
      {label ?? name}
    </div>
  );
}
