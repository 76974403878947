import { Button, Icon } from "@/components";
import "./styles.scss";
import { ProfileCard } from "./ProfileCard";
import { useEffect, useState } from "react";
import EditListType from "./EditListType";
import { Profile } from "@/types/profile";
import { interestList, languageList } from "@/mock/profile";

export function EditProfile({
  open,

  onClose,
  profile,
}: {
  profile: Profile;
  open: boolean;
  onClose: () => void;
}) {
  const [tempProfile, setTempProfile] = useState<Profile>({ ...profile });

  useEffect(() => setTempProfile(profile), [profile]);

  const handleProfileEdit = (value: string, name: keyof Profile) =>
    setTempProfile((p) => ({ ...p, [name]: value }));

  const [activeEdit, setActiveEdit] = useState<string>();
  const removeLanguage = (language: Profile["speaks"][number]) => {
    const newList = tempProfile.speaks.filter(
      (item) => item.id !== language.id
    );
    setTempProfile((data) => ({ ...data, sepeaks: newList }));
  };
  const removeInterests = (interest: Profile["interests"][number]) => {
    const newList = tempProfile.speaks.filter(
      (item) => item.id !== interest.id
    );
    setTempProfile((data) => ({ ...data, interests: newList }));
  };

  const setLanguageActive = () => setActiveEdit("languages");
  const setInterestsActive = () => setActiveEdit("interests");

  if (!open) return <></>;
  if (activeEdit === "languages")
    return (
      <div className="active-list-edit">
        <Button
          variant="neutral"
          className="back-btn"
          onClick={() => setActiveEdit(undefined)}
        >
          <Icon name="left_arrow" />
          <span>Back</span>
        </Button>

        <EditListType
          title="Pick Languages"
          list={languageList}
          onChange={(languages) =>
            setTempProfile((data) => ({
              ...data,
              speaks: languages,
            }))
          }
          selected_items={tempProfile.speaks}
        />
      </div>
    );
  if (activeEdit === "interests")
    return (
      <div className="active-list-edit">
        <Button
          variant="neutral"
          className="back-btn"
          onClick={() => setActiveEdit(undefined)}
        >
          <Icon name="left_arrow" />
          <span>Back</span>
        </Button>
        <EditListType
          title="Pick Interests"
          list={interestList}
          onChange={(interests) =>
            setTempProfile((data) => ({
              ...data,
              interests,
            }))
          }
          selected_items={tempProfile.interests}
        />
      </div>
    );
  return (
    <div className="edit_profile--container">
      <Button variant="neutral" className="close-btn" onClick={onClose}>
        <Icon name="close" />
        <span>Close</span>
      </Button>

      <h1 className="heading">Edit Profile</h1>

      <ProfileCard
        title="Display Name"
        value={tempProfile.fullname}
        type="text"
        isEditable
        onChange={(value) => handleProfileEdit(value, "fullname")}
      />
      <ProfileCard
        title="Date of Birth"
        value={tempProfile.dob}
        type="text"
        isEditable
        onChange={(value) => handleProfileEdit(value, "dob")}
      />
      <ProfileCard
        title="Gender"
        value="Male"
        type="text"
        isEditable
        onChange={(value) => handleProfileEdit(value, "gender")}
      />
      <ProfileCard
        title="Nationality"
        value="Nigerian"
        type="text"
        isEditable
        onChange={(value) => handleProfileEdit(value, "nationality")}
      />
      <ProfileCard
        title="Currently Lives in"
        value="Lagos, Nigeria"
        type="text"
        isEditable
        onChange={(value) => handleProfileEdit(value, "address")}
      />
      <ProfileCard
        title="Speaks"
        value={tempProfile.speaks}
        type="list"
        onRemoveItem={removeLanguage}
        onClick={setLanguageActive}
        isEditable
      />
      <ProfileCard
        title="Interest"
        value={tempProfile.interests}
        type="list"
        onRemoveItem={removeInterests}
        onClick={setInterestsActive}
        isEditable
      />
    </div>
  );
}
