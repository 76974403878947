import { ReactNode } from "react";
import "./styles.scss";
import SideBar from "./side-bar/SideBar";
import BottomNav from "./bottom-bar/BottomNav";
import Header from "./header/Header";

export default function DashboardLayout({ children }: { children: ReactNode }) {
  return (
    <div className="dashboard_layout--container">
      <SideBar />
      <BottomNav />
      <div className="right">
        <Header />
        <div className="dashboard-children">{children}</div>
      </div>
    </div>
  );
}
