import { Calendar } from "@/components";
import "./styles.scss";

export const TeachersCalendar = () => {
  return (
    <div className="teachers_calendar--container">
      <h1>Check Teacher Availability</h1>
      <Calendar />
      <div className="sessions">
        <div className="session available">
          <div className="circle"></div>
          <span>Booked Session</span>
        </div>
        <div className="session unavailable">
          <div></div>
          <span>Teacher Unavailable</span>
        </div>
      </div>
      <div className="instruction">
        <h1>Available Time</h1>
        <h3>Click on a day to see teacher's available time</h3>
      </div>
    </div>
  );
};
