import { configureStore } from "@reduxjs/toolkit";
import completedSchedule from "./slice/schedule/completed";
import detail from "./slice/schedule/detail";
import mark from "./slice/schedule/mark-as-completed";
import upcomingSchedule from "./slice/schedule/upcoming";
import report from "./slice/schedule/report";
import reviewCheck from "./slice/schedule/review-check";
import review from "./slice/schedule/review";
import reviewUpdate from "./slice/schedule/review-update";

export const store = configureStore({
  reducer: {
    completedSchedule,
    detail,
    mark,
    upcomingSchedule,
    report,
    reviewCheck,
    review,
    reviewUpdate,
  },
  // middleware: (getDefaultMiddleware) => [
  //   ...getDefaultMiddleware({
  //     serializableCheck: false,
  //   }),
  // ],
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
