import "./index.scss";

interface ListProps<T> {
  items: T[];
  ItemRender: (props: { item: T }) => JSX.Element;
  list_key: string;
  emptyMessage?: string;
}

export default function List<T>({
  items,
  list_key,
  ItemRender,
  emptyMessage = "This list is empty",
}: ListProps<T>) {
  return !items || items?.length < 1 ? (
    <div className="list-empty">
      <h3>{emptyMessage}</h3>
    </div>
  ) : (
    <div className="list-container">
      {items?.map((item: any, index: any) => (
        <ItemRender item={item} key={list_key + index} />
      ))}
    </div>
  );
}
