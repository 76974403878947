import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";
import { GET } from "@/services/api-request";
import { toast } from "sonner";
import { NavigateFunction } from "react-router-dom";

export interface Review {
  rating: number;
  review: string;
  reviewed?: boolean;
}

interface ReviewCheckState {
  data: Review | null;
  loading: boolean;
  error: string | null;
}

interface ReviewCheckParams {
  token: string;
  id: string | number;
  reviewValues: Review;
  setReviewValues: (values: Review) => void;
}

const initialState: ReviewCheckState = {
  data: null,
  loading: false,
  error: null,
};

export const fetchReviewData = createAsyncThunk(
  "reviewCheck/fetchReviewData",
  async (
    { token, id, reviewValues, setReviewValues }: ReviewCheckParams,
    { rejectWithValue }
  ) => {
    try {
      const { response } = await GET(
        `/reviews/student-booking-review/${id}`,
        token
      );

      setReviewValues({
        ...reviewValues,
        rating: response.data.result?.result?.review?.rating || "",
        review: response.data.result?.result?.review?.review || "",
      });
      const review = response?.data?.result.result || {};

      return review;
    } catch (error: any) {
      toast.error(error.response?.data?.message || "Unknown error");
      return rejectWithValue(error.response?.data?.message || "Unknown error");
    }
  }
);

// Review slice
const reviewCheckSlice = createSlice({
  name: "reviewCheck",
  initialState,
  reducers: {
    resetReviewState: (state) => {
      state.data = null;
      state.loading = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchReviewData.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        fetchReviewData.fulfilled,
        (state, action: PayloadAction<Review>) => {
          state.data = action.payload;
          state.loading = false;
        }
      )
      .addCase(
        fetchReviewData.rejected,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.error = action.payload;
        }
      );
  },
});

export const { resetReviewState } = reviewCheckSlice.actions;
export default reviewCheckSlice.reducer;
