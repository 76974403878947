import { Profile } from "@/types/profile";

export const languageList = [
  {
    id: "1",
    value: "Igbo",
  },
  {
    id: "2",
    value: "Yoruba",
  },
  {
    id: "3",
    value: "Urhobo",
  },
  {
    id: "4",
    value: "Hausa",
  },
  {
    id: "5",
    value: "Swahili",
  },
];

export const interestList = [
  {
    id: "1",
    value: "Film",
  },
  {
    id: "2",
    value: "Sports",
  },
  {
    id: "3",
    value: "music",
  },
  {
    id: "4",
    value: "arts",
  },
  {
    id: "5",
    value: "fitnes",
  },
  {
    id: "6",
    value: "yoga",
  },
  {
    id: "7",
    value: "art",
  },
  {
    id: "8",
    value: "books",
  },
];

export const profile: Profile = {
  fullname: "Nonso Okafor",
  email: "nonsokingsley61@gmail.com",
  isVerified: true,
  phone: "08109841426",
  avatar: null,
  nationality: "Nigerian",
  gender: "male",
  timezone: "WAT",
  address: "Lagos, Nigeria",
  dob: new Date().toLocaleDateString(),
  speaks: languageList.slice(0, 2).map((language) => language),
  interests: interestList.slice(0, 2).map((interest) => interest),
};
