import { Button } from "@/components";
import "./style.scss";
import { useState, useRef, useEffect } from "react";

export default function GotMail() {
  const [otp, setOtp] = useState(["", "", "", ""]); 
  const inputRefs = useRef<HTMLInputElement[]>([]); 

  const handleChange = (index: number, value: string) => {
    if (!/^\d?$/.test(value)) return; 

    const updatedOtp = [...otp];
    updatedOtp[index] = value.slice(0, 1); 
    setOtp(updatedOtp);

    if (value && index < inputRefs.current.length - 1) {
      inputRefs.current[index + 1]?.focus();
    }
  };

  const handleKeyDown = (index: number, e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Backspace" && !otp[index] && index > 0) {
      inputRefs.current[index - 1]?.focus();
    }
  };

  useEffect(() => {
    inputRefs.current[0]?.focus();
  }, []);

  return (
    <div className="got-mail-layout">
      <div className="got-mail-sidebar">
        <h1 className="got-mail-sidebar-h1">You've got mail</h1>
        <p className="got-mail-sidebar-p">
          We sent a verification code to your mail. Check your mail and input the code below.
        </p>

        <form id="continueBtn" className="got-mail-input-row">
          {otp.map((digit, index) => (
            <input
              key={index}
              ref={(el) => (inputRefs.current[index] = el!)} 
              className="got-mail-single-input"
              type="number"
              value={digit}
              onChange={(e) => handleChange(index, e.target.value)}
              onKeyDown={(e) => handleKeyDown(index, e)}
              autoFocus={index === 0} 
            />
          ))}
        </form>
        <p className="got-mail-p">Didn't receive an email?</p>
        <p className="got-mail-resend-code">Resend code</p>

        <Button
          form="continueBtn"
          variant="primary"
          className="got-mail-continue-btn"
          isLoading={false}
          disabled={false}
        >
          Continue
        </Button>
      </div>

      <div className="got-mail-bg-body"></div>
    </div>
  );
}
