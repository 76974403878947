import { Icon } from "@/components";
import "./styles.scss";

export default function Wallet() {
  return (
    <div className="wallet_container">
      <h1 className="hide_balance">Hide Wallet Balance</h1>
      <div className="wallet">
        <div className="top">
          <Icon name="wallet" />
          <span>Wallet Balance</span>
        </div>
        <div className="main">
          <div className="row">
            <h1 className="left">3,000</h1>
            <Icon name="plus_in_circle" />
          </div>
        </div>
      </div>
    </div>
  );
}
