import "./style.scss";
import Logo from "../../image/LogoSvg.png";
import { toast } from "sonner";

import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "@/components";
import { TextInput } from "@/components";

export default function ForgotPassword() {
  const [email, setEmail] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const navigate = useNavigate();

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const payload = { email };
    const api = `https://api.pluritongues.com`;
    setIsLoading(true);

    try {
      const response = await fetch(`${api}/auth/forgot-password`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });

      if (response.ok) {
        const data = await response.json();
        console.log(data);
      } else {
        toast.error("Invalid Email");
      }
    } catch (err) {
      console.log("Error posting data: ", err);
      toast.error("Couldn't sign you in");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <div className="forgot-password-header">
        <img className="forgot-password-logo" src={Logo} alt="logo" />
        <h1 className="forgot-password-pluritongues">Pluritongues</h1>
      </div>

      <div className="forgot-password-layout">
        <div className="forgot-password-sidebar">
          <div
            className="forget-password-back-arrow-wrapper"
            onClick={() => navigate("/")}
          >
            <i className="fas fa-arrow-left forget-password-back-arrow"></i>
            <div className="forgot-password-back-text">Back</div>
          </div>

          <h1>Forgot Password</h1>
          <p>
            Please type in the email address linked to your PLURITONGUES
            account.
          </p>

          <form
            onSubmit={handleSubmit}
            className="forget-password-form-wrapper"
          >
            <TextInput
              label={"Email"}
              type="email"
              placeholder="email"
              name="email"
              className=""
              onChange={(e) => setEmail(e.target.value)}
            />

            <Button
              variant="primary"
              className="signin_btn"
              type="submit"
              isLoading={isLoading}
              disabled={isLoading}
            >
              Reset Password
            </Button>
          </form>

          <p className="forgot-password-footer">
            &copy; right <a href="https://pluritongues.com">Pluritongues</a>.
            All rights reserved
          </p>
        </div>
        <div className="forgot-password-bg-body"></div>
      </div>
    </>
  );
}

//     return (
//         <div className="forgot-password-header">
//            <div>

//            </div>
//            <div>
//             <Button>Sign In<Button>
//            </div>
//         </div>

//     )
// }

{
  /* <div className="forget-password-form-wrapper">
            <div className="forget-wrapper">
            <p>Forgot password</p>
             <form className="forget-password-form" */
}
{
  /* onSubmit={handleSubmit}>
                 <label>Email</label>
                 <input 
                 type="text"
                 value={email}
                 onChange={(e) => setEmail(e.target.value)}
                 autoFocus
                  /> */
}

{
  /* {error && <p>{error}</p>}

                  <button type="submit">
                     Submit
                  </button>
             </form>
            </div> */
}

{
  /* </div> */
}
