import Button from "@/components/Button";
import Icon from "@/components/Icon";
import { useState, useRef, useMemo } from "react";
import { Verdict } from "../../Verdict";
import { TapWhatYouHearQuestion as TapWhatYouHearQuestionType } from "@/types/activity";
import "./styles.scss";

export const TapWhatYouHearQuestion = ({
  question,
  handleNextQuestion,
}: {
  question: TapWhatYouHearQuestionType;
  handleNextQuestion: () => void;
}) => {
  const [selectedOptionId, setSelectedOptionId] = useState<string[]>([]);
  const [isAudioPlaying, setIsAudioPlaying] = useState(false);
  const [canCheck, setCanCheck] = useState(false);
  const audioRef = useRef<HTMLAudioElement>(null);
  const answers = question.answer;
  const is_answer = useMemo(
    () =>
      selectedOptionId.every(
        (option_id, index) => answers[index] === option_id
      ) && selectedOptionId.length === answers.length,
    [answers, selectedOptionId]
  );

  const answer = useMemo(() => {
    const lastSelectedId = selectedOptionId[selectedOptionId.length - 1];
    if (answers?.includes(lastSelectedId) === false) return "";
    const option = question.options.find(
      (option) => option.id === lastSelectedId
    );
    return option?.text ?? "";
  }, [answers, selectedOptionId, question.options]);

  const playAudio = () => {
    if (isAudioPlaying) {
      audioRef.current?.pause();
      audioRef.current!.currentTime = 0;
    } else audioRef.current?.play();
  };

  const handleClickOption = (optionId: string) => {
    setCanCheck(false);
    setSelectedOptionId((prev) =>
      selectedOptionId.includes(optionId)
        ? prev.filter((o) => o !== optionId)
        : [...prev, optionId]
    );
  };

  const handleCheck = () => setCanCheck(true);
  return (
    <div className="question_container" data-type={question.activityType}>
      <div className="top">
        <h1>Tap in what you hear</h1>
        <div className="language_container">
          <Icon name="language" />
        </div>
      </div>
      <div className="middle">
        <Button
          variant="neutral"
          className="audio_container"
          onClick={playAudio}
        >
          <Icon name="audio" />
          <audio
            src={question.question.audioUrl}
            ref={audioRef}
            onPlay={() => setIsAudioPlaying(true)}
            onPause={() => setIsAudioPlaying(false)}
          ></audio>
        </Button>
        <Button variant="neutral" className="cantlisten">
          CAN'T LISTEN NOW
        </Button>
      </div>
      <div className="selected_options">
        {selectedOptionId.map((option_id) => {
          const option = question.options.find(
            (option) => option_id === option.id
          );

          if (option)
            return (
              <div className="option_container">
                <TapWhatYouHearOption
                  onClick={handleClickOption}
                  canCheck={canCheck}
                  option={option}
                  selectedOptionId={selectedOptionId}
                  key={option.id}
                  is_answer={
                    answers.findIndex(
                      (answer_id) => answer_id === option.id
                    ) ===
                      selectedOptionId.findIndex(
                        (option_id) => option_id === option.id
                      ) &&
                    answers.findIndex(
                      (answer_id) => answer_id === option.id
                    ) !== -1
                  }
                />
              </div>
            );
          else return <></>;
        })}
      </div>
      <div className="options">
        {question.options.map((option) => (
          <TapWhatYouHearOption
            onClick={handleClickOption}
            canCheck={canCheck}
            option={option}
            selectedOptionId={selectedOptionId}
            key={option.id}
            is_answer={
              answers.findIndex((answer_id) => answer_id === option.id) ===
                selectedOptionId.findIndex(
                  (option_id) => option_id === option.id
                ) &&
              answers.findIndex((answer_id) => answer_id === option.id) !== -1
            }
          />
        ))}
      </div>

      <Button
        disabled={selectedOptionId === null}
        variant="primary"
        className="check-btn"
        onClick={handleCheck}
      >
        Check
      </Button>
      <Verdict
        is_answer={is_answer}
        answer={answer}
        open={canCheck}
        onClose={() => setCanCheck(false)}
        handleNext={handleNextQuestion}
      />
    </div>
  );
};

const TapWhatYouHearOption = ({
  option,
  selectedOptionId,
  canCheck,
  onClick,
  is_answer,
}: {
  option: TapWhatYouHearQuestionType["options"][number];
  canCheck: boolean;
  selectedOptionId: string[];
  onClick: (option_id: string) => void;
  is_answer: boolean;
}) => {
  return (
    <Button
      variant="neutral"
      key={option.id}
      className="option"
      data-selected={String(selectedOptionId.includes(option.id))}
      data-correct={String(
        canCheck && selectedOptionId.includes(option.id) && is_answer
      )}
      data-wrong={String(
        canCheck && selectedOptionId.includes(option.id) && !is_answer
      )}
      onClick={() => {
        onClick(option.id);
      }}
    >
      <p>{option.text}</p>
    </Button>
  );
};
