import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";
import { GET } from "../../../services/api-request";
import { detail } from "@/types/schedules";

interface CompletedScheduleDetailState {
  data: detail | null;
  loading: boolean;
  error: string | null;
}

const initialState: CompletedScheduleDetailState = {
  data: null,
  loading: false,
  error: null,
};

export const scheduleDetailFetch = createAsyncThunk<
  detail,
  { token: string; id: string },
  { rejectValue: string }
>(
  "completedScheduleDetail/fetch",
  async ({ token, id }, { rejectWithValue }) => {
    try {
      const { response } = await GET(`/bookings/lessons/${id}`, token);

      return response?.data?.result;
    } catch (err: any) {
      return rejectWithValue(err.response?.data?.message || "Unknown error");
    }
  }
);

const scheduleDetailSlice = createSlice({
  name: "detail",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(scheduleDetailFetch.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        scheduleDetailFetch.fulfilled,
        (state, action: PayloadAction<detail>) => {
          state.data = action.payload;
          state.loading = false;
          state.error = null;
        }
      )
      .addCase(
        scheduleDetailFetch.rejected,
        (state, action: PayloadAction<string | undefined>) => {
          state.loading = false;
          state.error = action.payload || "An error occurred.";
        }
      );
  },
});

export default scheduleDetailSlice.reducer;
