import { Button, Icon } from "@/components";
import { ProfileCard } from "../../EditProfile/ProfileCard";
import { Profile } from "@/types/profile";
import "./styles.scss";
import { useEffect, useState } from "react";
import SetTimeZone from "./SetTimeZone";

export default function GeneralSettings({
  profile,
  onClose,
}: {
  profile: Profile;
  onClose: () => void;
}) {
  const [temProfile, setTempProfile] = useState({ ...profile });

  useEffect(() => setTempProfile(profile), [profile]);

  const handleProfileEdit = (value: string, name: keyof Profile) =>
    setTempProfile((p) => ({ ...p, [name]: value }));

  //@ts-ignore
  const timezones = Intl.supportedValuesOf("timeZone").map((value, index) => ({
    id: `${index}`,
    value,
  }));

  const [selectedTZ, setSelectedTZ] = useState<
    (typeof timezones)[number] | null
  >(timezones[0]);
  const [activeSetting, setActiveSetting] = useState("");

  if (activeSetting === "timezone")
    return (
      <div className="active-setting-edit">
        <Button
          variant="neutral"
          className="back-btn"
          onClick={() => setActiveSetting("")}
        >
          <Icon name="left_arrow" />
          <span>Back</span>
        </Button>
        <SetTimeZone
          list={timezones}
          selected_item={selectedTZ}
          onChange={(timezone) => setSelectedTZ(timezone)}
        />
      </div>
    );
  return (
    <div className="general-settings">
      <Button variant="neutral" className="back-btn" onClick={onClose}>
        <Icon name="left_arrow" />
        <span>Back</span>
      </Button>

      <h1 className="heading">General Settings</h1>

      <div className="list">
        <ProfileCard
          title={
            <p
              className="email-title"
              data-verified={String(temProfile.isVerified)}
            >
              Email{" "}
              {temProfile.isVerified ? (
                <span>Verified</span>
              ) : (
                <span>Unverified</span>
              )}
            </p>
          }
          value={temProfile.email}
          onClick={() => null}
          type="text"
          isEditable
          onChange={(value) => handleProfileEdit(value, "email")}
        />
        <ProfileCard
          title={"Phone Number"}
          value={temProfile.phone}
          onClick={() => null}
          type="text"
          isEditable
          onChange={(value) => handleProfileEdit(value, "phone")}
        />
        <ProfileCard
          title={"Timezone"}
          value={selectedTZ?.value ?? ""}
          onClick={() => setActiveSetting("timezone")}
          type="text"
          isEditable
          onChange={() => null}
        />
        <ProfileCard
          title={"Time format"}
          value={"12H"}
          onClick={() => null}
          type="text"
          isEditable
          onChange={() => null}
        />
      </div>
    </div>
  );
}
