import Button from "../Button";
import Icon from "../Icon";
import { InputProps } from "../TextInput";
import "./styles.scss";

interface SearchInputProps extends InputProps {
  containerClassname?: string;
}

export default function SearchInput({
  containerClassname,
  ...props
}: SearchInputProps) {
  return (
    <div className={`search_input--container ${containerClassname}`}>
      <Icon name="lens" />
      <input type="text" {...props} />
      <Button variant="neutral">
        <Icon name="close" width={16} height={16} />
      </Button>
    </div>
  );
}
