import DashboardLayout from "@/layouts/dashboard-layout";
import { ScheduleHeader } from "../schedule-header";
import { useEffect, useState } from "react";
import "./review-styles.scss";
import {
  fetchReviewData,
  Review as ReviewType,
} from "@/redux/slice/schedule/review-check";
import { useAppSelector, useAppDispatch } from "@/redux/hooks";
import { useAuth } from "@/contexts/auth-context";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { reviewUpdateFn } from "@/redux/slice/schedule/review-update";
import { submitReview } from "@/redux/slice/schedule/review";

export const Review = () => {
  const [reviewValues, setReviewValues] = useState({
    rating: 1,
    review: "",
    error: "",
    reviewed: false,
  });

  const { lesson_id } = useParams();
  const navigate = useNavigate();

  const location = useLocation();

  const { marchantId, bookingId, fullName, dpUrl } = location.state;

  const dispatch = useAppDispatch();

  const { token } = useAuth();

  const handleRating = (index: number) => {
    setReviewValues({ ...reviewValues, rating: index + 1 });
  };

  const {
    data: checkData,
    error: checkError,
    loading: checkLoading,
  } = useAppSelector((state) => state.reviewCheck);
  const { data, error, loading } = useAppSelector((state) => state.review);
  const {
    data: updateData,
    error: updateError,
    loading: updateLoading,
  } = useAppSelector((state) => state.reviewUpdate);

  const check = () => {
    dispatch(
      fetchReviewData({
        token: token || "",
        id: lesson_id || "",
        reviewValues,
        setReviewValues: (values: ReviewType) =>
          setReviewValues({ ...reviewValues, ...values }),
      })
    );
  };

  useEffect(() => {
    check();
  }, []);

  const fetch = () => {
    if (checkData && checkData.reviewed) {
      dispatch(
        reviewUpdateFn({
          token: token || "",
          reviewValues,
          bookingId: bookingId || "",
          marchantId: marchantId || "",
          setReviewValues: (values: ReviewType) =>
            setReviewValues({ ...reviewValues, ...values }),
          goBack: navigate,
        })
      );
    } else {
      dispatch(
        submitReview({
          token: token || "",
          reviewValues,
          bookingId: bookingId || "",
          marchantId: marchantId || "",
          setReviewValues: (values: ReviewType) =>
            setReviewValues({ ...reviewValues, ...values }),
          goBack: navigate,
        })
      );
    }
  };

  const _postReview = () => {
    if (reviewValues.review.trim() === "") {
      setReviewValues({
        ...reviewValues,
        error: "Review text input cannot be empty",
      });
    } else {
      setReviewValues({ ...reviewValues, error: "" });
      try {
        if (reviewValues.review === "") {
          setReviewValues({
            ...reviewValues,
            error: "Review text input cannot be empty",
          });
        } else {
          fetch();
        }
      } catch (error) {
        setReviewValues({
          ...reviewValues,
          error: "something went wrong",
        });
        console.warn(error);
      }
    }
  };

  return (
    <DashboardLayout>
      <ScheduleHeader title="Leave a Review" />
      {checkLoading || updateLoading || loading ? (
        <p>loading</p>
      ) : (
        <div className="review-container">
          <div className="review-header">
            <p className="subtitle">
              Reviews are Public and will include your name and profile picture
            </p>
            <p className="description">You Just Completed A Lesson With:</p>
          </div>
          <div className="review-teacher-info">
            <img className="teacher-image" src={dpUrl} alt="img" />
            <p className="teacher-name">{fullName}</p>
          </div>
          <div className="rating-container">
            {Array.from({ length: 5 }, (_, index) => (
              <button
                key={index}
                className={`star ${
                  index < reviewValues.rating ? "filled" : ""
                }`}
                onClick={() => handleRating(index)}
              >
                {index < reviewValues.rating ? "★" : "☆"}
              </button>
            ))}
          </div>
          <textarea
            className="review-textarea"
            placeholder="Describe your experience with this teacher"
            value={reviewValues.review}
            onChange={(e) =>
              setReviewValues({ ...reviewValues, review: e.target.value })
            }
          />
          {reviewValues.error && (
            <p className="error-message">{reviewValues.error}</p>
          )}
          <button className="submit-button" onClick={_postReview}>
            Post Review
          </button>
        </div>
      )}
    </DashboardLayout>
  );
};
