import { Button, Icon } from "@/components";
import "./styles.scss";

export default function ContactUs({
  open,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
}) {
  if (!open) return <></>;
  return (
    <div className="contact_us--container">
      <Button variant="neutral" className="close-btn" onClick={onClose}>
        <Icon name="close" />
        <span>Close</span>
      </Button>

      <h1 className="heading">Contact Us</h1>
      <div className="btn-list">
        <Button variant="neutral" className="btn-item">
          <div className="icon-container">
            <Icon name="phone" />
          </div>
          <div>
            <span className="title">Call us</span>
            <span className="sub-title">
              Speak to someone from our call center
            </span>
          </div>
        </Button>
        <Button variant="neutral" className="btn-item">
          <div className="icon-container">
            <Icon name="envelope" />
          </div>
          <div>
            <span className="title">Send an email</span>
            <span className="sub-title">
              Speak to someone from our call center
            </span>
          </div>
        </Button>
        <Button variant="neutral" className="btn-item">
          <div className="icon-container">
            <Icon name="whatsapp" />
          </div>
          <div>
            <span className="title">Chat with us</span>
            <span className="sub-title">
              Speak to someone from our call center
            </span>
          </div>
        </Button>
      </div>
      <h1 className="heading">Reach us on social media</h1>
      <div className="btn-list">
        <Button variant="neutral" className="btn-item">
          <div className="icon-container">
            <Icon name="x_logo" />
          </div>
          <div>
            <span className="title">X</span>
          </div>
        </Button>
        <Button variant="neutral" className="btn-item">
          <div className="icon-container">
            <Icon name="instagram" />
          </div>
          <div>
            <span className="title">Instagram</span>
          </div>
        </Button>
        <Button variant="neutral" className="btn-item">
          <div className="icon-container">
            <Icon name="linkedin" />
          </div>
          <div>
            <span className="title">LinkedIn</span>
          </div>
        </Button>
        <Button variant="neutral" className="btn-item">
          <div className="icon-container">
            <Icon name="facebook" />
          </div>
          <div>
            <span className="title">facebook</span>
          </div>
        </Button>
      </div>
    </div>
  );
}
