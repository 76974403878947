import { useState } from "react";
import "./style.scss";
import { Button } from "@/components";
import Checkbox from "@/components/CheckBox";

export default function NewPassword() {

    const [password, setPassword] = useState<string>("");
    const [reEnterPassword, setReEnterPassword] = useState<string>("");

    const [inputType, setInputType] = useState<string>("password");
    const [secondInputType, setSecondInputType] = useState<string>("password")

    const [showPassword, setShowPassword] = useState<boolean>(false);
    const [showSecondPassword, setShowSecondPassword] = useState<boolean>(false);

    const [error, setError] = useState<boolean>(false);

    const [isLoading, setIsLoading] = useState<boolean>(false);

    const [check, setCheck] = useState<false>(false);

    const handleToggle = () => {
       setShowPassword(!showPassword)
       setInputType(showPassword ? "password" : "text")
    }

    const handleSecondToggle = () => {
        setShowSecondPassword(!showSecondPassword)
        setSecondInputType(showSecondPassword ? "password" : 'text')
    }


    const handleSubmit = () => {
        console.log("submitting.....")
    }

    return (
        <div className="new-password-layout">
            <div className="new-password-sidebar">

                <h1 className="new-password-sidebar-h1">Create a new password</h1>
                <p className="new-password-sidebar-p">Safe your new password in a secure place. If you forget your password again, you will have to do a forgot password again.</p>

                <form
                    onSubmit={handleSubmit}
                    className="new-password-form-wrapper"
                >

                    <label className="new-password-label">Create a new password</label>
                    <input
                        type={inputType}
                        className="new-password-input"
                        placeholder="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        autoFocus
                    />

                    <span
                        onClick={handleToggle}
                        className="new-password-show-toggle">
                        {showPassword ? (<p>Hide</p>) : (<p>Show</p>)}
                    </span>

                    <label className="new-password-label">Confirm new password</label>
                    <input 
                    className="new-password-second-input"
                    type={secondInputType}
                    value={reEnterPassword}
                    placeholder="password"
                    onChange={(e) => setReEnterPassword(e.target.value)}
                    />
                    <span
                    onClick={handleSecondToggle}
                    className="new-password-show-second-toggle" >
                    {showSecondPassword ? (<p>Hide</p>) : (<p>Show</p>)  }
                    
                    </span>

                    {error && (<div className="new-password-error">{error}</div>)}

                    <Button 
                    variant="primary"
                    isLoading={isLoading}
                    className="new-password-btn"
                    >
                        Continue
                    </Button>

                </form>

            </div>

            <div className="new-password-bg-body">

            </div>

        </div>
    )
}