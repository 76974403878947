import { createContext, ReactNode, useContext, useState } from "react";
import { request } from "@/services/request";
import { parseJSON, removeMultipleKeys } from "@/utils/function";

type UserDetails = {
  id: string;
  // Add other properties if needed
};

interface IAuthContext {
  token: string | null;
  details: UserDetails | null;
  isAuthenticated: boolean;
  handleLogin?: (args: Login) => Promise<void>;
  handleLogout?: () => void;
}

const TOKEN_KEY = "pluri_token";
const USER_DETAIL_KEY = "user_detail";

const INITIAL_STATE: IAuthContext = {
  token: localStorage.getItem(TOKEN_KEY),
  details: parseJSON<UserDetails>(localStorage.getItem(USER_DETAIL_KEY)),
  isAuthenticated: !!localStorage.getItem(TOKEN_KEY),
};

export interface Login {
  email: string;
  password: string;
}

export const AuthContext = createContext<IAuthContext>(INITIAL_STATE);

export function AuthContextProvider({ children }: { children: ReactNode }) {
  const [token, setToken] = useState<string | null>(
    localStorage.getItem(TOKEN_KEY)
  );
  const [details, updateDetails] = useState<UserDetails | null>(() =>
    parseJSON<UserDetails>(localStorage.getItem(USER_DETAIL_KEY))
  );

  const handleLogin = async ({ email, password }: Login) => {
    try {
      const { data } = await request.post(
        `${process.env.REACT_APP_API_URL}/auth/login`,
        {
          email,
          password,
        }
      );
      const token = data.result.accessToken;
      const detail = data.result.user;
      setToken(token);
      updateDetails(detail);
      localStorage.setItem(TOKEN_KEY, token);
      localStorage.setItem(USER_DETAIL_KEY, JSON.stringify(detail));
    } catch (error: any) {
      console.error("Login failed:", error.response?.data?.message || error);
      throw error; // Re-throw for further handling
    }
  };

  const handleLogout = () => {
    setToken(null);
    updateDetails(null);
    removeMultipleKeys([TOKEN_KEY, USER_DETAIL_KEY]);
  };

  return (
    <AuthContext.Provider
      value={{
        token,
        details,
        isAuthenticated: !!token,
        handleLogin,
        handleLogout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthContextProvider");
  }
  return context;
};
