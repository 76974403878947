import { Icon } from "@/components";
import "./header.scss";
import Icons from "@/components/Icon/icons";

export default function Header() {
  return (
    <div className="header">
      <h1 className="greeting">Hi, Okonkwo Kenechi</h1>

      <div className="description">
        <Icon name="message" />
        <div className="language-and-arrow">
          <div>Igbo</div>
          <Icon name="downArrow" />
        </div>
      </div>
    </div>
  );
}
