import { NavLink } from "react-router-dom";
import { Icon } from "@/components";
import { IconType } from "@/components/Icon/icons";
import "./navItem.scss";

interface NavItemProps {
  to: string;
  iconName: IconType;
  iconSize?: number;
  defaultColor?: string;
  activeColor?: string;
  children: React.ReactNode;
}

const NavItem = ({
  to,
  iconName,
  iconSize = 32,
  defaultColor = "#808080",
  activeColor = "#169FED",
  children,
}: NavItemProps) => {
  return (
    <NavLink
      className={({ isActive }) =>
        `bottom-link ${isActive ? "active-bottom-link" : ""}`
      }
      to={to}
    >
      {({ isActive }) => (
        <div className="nav-item">
          <Icon
            name={iconName}
            size={iconSize}
            color={isActive ? activeColor : defaultColor}
          />
          <span>{children}</span>
        </div>
      )}
    </NavLink>
  );
};

export default NavItem;
