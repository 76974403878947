import { useNavigate } from "react-router-dom";
import { Icon, Button } from "@/components";
import "./schedule-styles.scss";

interface ScheduleHeaderProps {
  title: string;
}

export const ScheduleHeader: React.FC<ScheduleHeaderProps> = ({ title }) => {
  const navigate = useNavigate();

  return (
    <div className="schedule-header">
      <Button
        onClick={() => navigate(-1)}
        variant="neutral"
        className="go-back"
      >
        <Icon name="left_arrow" />
        <p>Back</p>
      </Button>
      <p className="title">{title}</p>
    </div>
  );
};
