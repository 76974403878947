import { useMemo, useState } from "react";
import { InputProps } from "../TextInput";
import "./styles.scss";

const PasswordInput = ({
  className,
  label,
  error,
  containerClassName,
  ...props
}: InputProps) => {
  const [showPassword, setShowPassword] = useState(false);
  const inputType = useMemo(
    () => (showPassword ? "text" : "password"),
    [showPassword]
  );

  return (
    <div className={`input_container--outer ${containerClassName}`}>
      {label && (
        <label htmlFor={props?.id} className={`label`}>
          {label}
          {props.required && "*"}
        </label>
      )}
      <div className={`input_container--inner ${className}`}>
        <input {...props} className="input" type={inputType || "text"} />
        <div
          onClick={(e) => {
            e.stopPropagation();
            setShowPassword((x) => !x);
          }}
          tabIndex={-1}
        >
          {showPassword ? "Hide" : "Show"}
        </div>
      </div>
      {error?.message && (
        <div className="">
          <p className="">{error.message}</p>
        </div>
      )}
    </div>
  );
};

export default PasswordInput;
