import { Button, SearchInput } from "@/components";
import "./styles.scss";
import Checkbox from "@/components/CheckBox";

interface Props<T extends { id: string; value: string }> {
  list: T[];
  selected_item: T | null;
  onChange: (value: T | null) => void;
}

export default function SetTimeZone<T extends { id: string; value: string }>({
  list,
  selected_item,
  onChange,
}: Props<T>) {
  const onClick = (clickedItem: T) => {
    if (clickedItem.id === selected_item?.id) onChange(null);
    else onChange(clickedItem);
  };

  return (
    <div className="set_timezone--container">
      <h1 className="heading">Set time zone</h1>
      <SearchInput />
      <div className="timezone-list">
        {list.map((item, index) => {
          console.log(item.id, selected_item?.id);
          return (
            <div onClick={() => onClick(item)} key={index} className="timezone">
              {item.value}
              <Checkbox
                checked={selected_item?.id === item.id}
                onCheck={(checked) =>
                  checked === true ? onChange(item) : onChange(null)
                }
              />
            </div>
          );
        })}
      </div>
    </div>
  );
}
