import { MouseEventHandler } from "react";
import "./styles.scss";
import Icon from "../Icon";

interface Props {
  checked: boolean;
  onCheck: (checked: boolean) => void;
}

export default function Checkbox({ checked, onCheck }: Props) {
  const onClick: MouseEventHandler<HTMLDivElement> = (e) => {
    e.stopPropagation();
    onCheck(!checked);
  };
  console.log(checked);
  return (
    <div
      data-checked={String(checked)}
      onClick={onClick}
      className="checkbox-container"
    >
      {checked && <Icon name="check" />}
    </div>
  );
}
