import DashboardLayout from "@/layouts/dashboard-layout";
import "./styles.scss";
import { Avatar, Button, Icon } from "@/components";
import Wallet from "./components/Wallet";
import ProfileButton from "./components/ProfileButton";
import { EditProfile } from "./components/EditProfile";
import { useState } from "react";
import { profile } from "@/mock/profile";
import ContactUs from "./components/ContactUs";
import Settings from "./components/Settings";

export default function Profile() {
  const [openEdit, setOpenEdit] = useState(false);
  const [openSettings, setOpenSettings] = useState(false);
  const [openContact, setOpenContact] = useState(false);

  const handleOpenSettings = () => {
    setOpenSettings(true);
    setOpenEdit(false);
    setOpenContact(false);
  };

  const handleOpenContact = () => {
    setOpenSettings(false);
    setOpenEdit(false);
    setOpenContact(true);
  };

  const handleOpenEdit = () => {
    setOpenSettings(false);
    setOpenEdit(true);
    setOpenContact(false);
  };

  return (
    <DashboardLayout>
      <div className="profile_container">
        <div className="left">
          <div className="main_profile">
            <Avatar />
            <h1>{profile.fullname}</h1>
            <Button
              variant="neutral"
              className="edit-profile-btn"
              onClick={handleOpenEdit}
            >
              <span>Edit Profile</span>
              <Icon name="caret_right" />
            </Button>
          </div>
          <Wallet />
          <ProfileButton
            icon="settings"
            title="Settings"
            onClick={handleOpenSettings}
          />
          <ProfileButton
            icon="contact"
            title="Contact Us"
            onClick={handleOpenContact}
          />
          <ProfileButton icon="logout" title="Log Out" />
        </div>
        <div className="right" data-show={String(openEdit || openSettings || openContact)}>
          <EditProfile
            profile={profile}
            open={openEdit}
            onClose={() => setOpenEdit(false)}
          />
          <ContactUs open={openContact} onClose={() => setOpenContact(false)} />
          <Settings
            profile={profile}
            open={openSettings}
            onClose={() => setOpenSettings(false)}
          />
        </div>
      </div>
    </DashboardLayout>
  );
}
