import { useAuth } from "@/contexts/auth-context";
import { ReactElement, useEffect } from "react";

import { useNavigate } from "react-router-dom";

function ProtectedRoute({ children }: { children: ReactElement }) {
  const { isAuthenticated } = useAuth();

  const navigate = useNavigate();

  useEffect(() => {
    if (!isAuthenticated) {
      navigate("/");
    }
  }, [isAuthenticated, navigate]);

  if (!isAuthenticated) return null;

  return children;
}

export default ProtectedRoute;
