import DashboardLayout from "@/layouts/dashboard-layout";
import { activities } from "@/mock/activties";
import ActivityDetailCard from "./ActivityDetailCard";

export function Activties() {
  return (
    <DashboardLayout>
      <div>
        {activities.map((activity, index) => (
          <ActivityDetailCard activity={activity} key={index} />
        ))}
      </div>
    </DashboardLayout>
  );
}
