import "./interactive-lesson-card.scss";
import { Button } from "@/components";

interface DashBoardTeacherCardProps {
  title: string;
  buttoText: string;
}

export function InteractiveLessonCard() {
  return (
    <article className="dashboard-interactive-card">
      <div className="item">A</div>
      <div className="item">
        <h3>Interactive Activities</h3>
        <p>Level A1 (Beginner)</p>
        <p>0/10 Lessons</p>
        <progress value="0" max="100"></progress>
      </div>
      <Button className="item" variant="secondary">
        Start
      </Button>
    </article>
  );
}

export function InteractiveLessonCardx() {
  return (
    <article className="grid-container">
      <div className="item">A</div>
      <div className="item">
        <h2>Interactive Activities</h2>
        <p>Level A1 (Beginner)</p>
        <p>0/10 Lessons</p>
      </div>
      <Button className="item" variant="secondary">
        Start
      </Button>
    </article>
  );
}
export function DashBoardTeacherCard({
  title,
  buttoText,
}: DashBoardTeacherCardProps) {
  return (
    <article className="dashboard-card">
      {/* Note: Kind of prefer h3 for card heading since it might  be inside a section which is in a page */}
      <h3 className="card-title">{title}</h3>
      <button className="card-buttons">{buttoText}</button>
    </article>
  );
}

export function ScheduleCard() {
  return (
    <article className="schedule-card">
      <section>
        <h3 className="schedule-title">Schedules</h3>
        <p className="inline-text">Find your class & session details here.</p>
      </section>
      <aside>
        {/* src/pages/home/interactive-lesson-card/images/schedule.svg */}
        <img src="/schedule.svg" alt="Pluritongues Schedule" />
      </aside>
    </article>
  );
}
