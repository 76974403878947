import DashboardLayout from "@/layouts/dashboard-layout";
import { ScheduleHeader } from "../schedule-header";
import React, { useState } from "react";
import { useAppDispatch, useAppSelector } from "@/redux/hooks";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { reportFn } from "@/redux/slice/schedule/report";
import "./report-styles.scss";
import { disputeReasons } from "@/mock/shedule";
import { useAuth } from "@/contexts/auth-context";

export const Dispute = () => {
  const [userReason, setUserReason] = useState("");
  const [details, setDetails] = useState("");
  const dispatch = useAppDispatch();
  const { data, error, loading } = useAppSelector((state) => state.report);
  const location = useLocation();

  const { marchantId, bookingId } = location.state;

  const navigate = useNavigate();
  const { token, details: userDetails } = useAuth();

  const handleReport = () => {
    try {
      dispatch(
        reportFn({
          token: token || "",
          bookingId: bookingId || "",
          marchantId: marchantId || "",
          problem: userReason,
          description: details,
          navigate: () => navigate(-1),
        })
      );
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <DashboardLayout>
      <ScheduleHeader title="Report Teacher" />
      {loading ? (
        <p>Loading</p>
      ) : (
        <div className="report-container">
          <div className="report-content">
            <h2 className="subtitle">
              Please select the option that best describes the problem
            </h2>
            <div className="reasons-list">
              {disputeReasons.map((reason) => (
                <div
                  key={reason.key}
                  className={"reason-item"}
                  onClick={() => setUserReason(reason.value)}
                >
                  <span>{reason.value}</span>
                  <div className="radio">
                    <div
                      className={`radio-inner ${
                        userReason !== reason.value ? "inactive" : ""
                      }`}
                    ></div>
                  </div>
                </div>
              ))}
            </div>
            <label className="details-label">
              Please enter additional details relevant to your report
            </label>
            <textarea
              placeholder="Additional details..."
              className="details-input"
              value={details}
              onChange={(e) => setDetails(e.target.value)}
            />
            <button
              className="report-button"
              onClick={handleReport}
              disabled={!userReason || !details}
            >
              Dispute
            </button>
          </div>
        </div>
      )}
    </DashboardLayout>
  );
};
