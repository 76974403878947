import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";
import { GET } from "../../../services/api-request";
import { CompletedScheduleData } from "@/types/schedules";

interface CompletedScheduleState {
  data: CompletedScheduleData[];
  loading: boolean;
  error: string | null;
}

const initialState: CompletedScheduleState = {
  data: [],
  loading: false,
  error: null,
};

export const completedScheduleDataFetch = createAsyncThunk<
  CompletedScheduleData[],
  string,
  { rejectValue: string }
>("completedSchedule/fetch", async (token, { rejectWithValue }) => {
  try {
    const { response } = await GET(
      `/bookings?completed=true&limit=10&offset=0`,
      token
    );
    
    return response?.data?.result.theBookings || [];
  } catch (err: any) {
    return rejectWithValue(err.response?.data?.message || "Unknown error");
  }
});

const completedScheduleDataSlice = createSlice({
  name: "completedSchedule",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(completedScheduleDataFetch.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        completedScheduleDataFetch.fulfilled,
        (state, action: PayloadAction<CompletedScheduleData[]>) => {
          state.data = action.payload;
          state.loading = false;
          state.error = null;
        }
      )
      .addCase(
        completedScheduleDataFetch.rejected,
        (state, action: PayloadAction<string | undefined>) => {
          state.loading = false;
          state.error = action.payload || "An error occurred.";
        }
      );
  },
});

export default completedScheduleDataSlice.reducer;
