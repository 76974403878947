import { TabSwitch } from "@/components/TabSwitch";
import { Teacher } from "@/types/teacher";
import "./styles.scss";

export const MoreDetails = ({ teacher }: { teacher: Teacher }) => {
  const tabList = [
    { name: "about_me", label: "About Me" },
    { name: "resume", label: "Resume" },
    { name: "reviews", label: "Reviews" },
  ];
  return (
    <div className="teacher_details--container">
      <TabSwitch list={tabList} defaultSelected="about_me" />
      <div></div>
      <div></div>
    </div>
  );
};
