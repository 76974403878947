import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";
import { POST } from "@/services/api-request";
import { toast } from "sonner";
import { NavigateFunction } from "react-router-dom";

interface ReviewValues {
  rating: number;
  review: string;
  error?: string;
}
interface ReviewParams {
  token: string;
  reviewValues: ReviewValues;
  bookingId: string;
  marchantId: string;
  setReviewValues: (values: ReviewValues) => void;
  goBack: NavigateFunction;
}

interface ReviewState {
  data: object | null;
  loading: boolean;
  error: string | null;
}

const initialState: ReviewState = {
  data: null,
  loading: false,
  error: null,
};

export const submitReview = createAsyncThunk(
  "review/submitReview",
  async (
    {
      token,
      reviewValues,
      bookingId,
      marchantId,
      setReviewValues,
      goBack,
    }: ReviewParams,
    { rejectWithValue }
  ) => {
    const raw = JSON.stringify({
      bookingId: bookingId,
      marchantId: marchantId,
      rating: reviewValues.rating,
      review: reviewValues.review,
    });

    try {
      const { response } = await POST(`/reviews/bookings`, raw, token);

      toast.success("Review submitted successfully");

      goBack(-1);
      return response;
    } catch (error: any) {
      const errorMessage = error.response?.data?.message || "Unknown error";

      setReviewValues({ ...reviewValues, error: errorMessage });

      toast.error(errorMessage || "something went wrong");

      return rejectWithValue(errorMessage);
    }
  }
);

const reviewSlice = createSlice({
  name: "review",
  initialState,
  reducers: {
    resetReviewState: (state) => {
      state.data = null;
      state.loading = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(submitReview.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        submitReview.fulfilled,
        (state, action: PayloadAction<object>) => {
          state.data = action.payload;
          state.loading = false;
          state.error = null;
        }
      )
      .addCase(submitReview.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { resetReviewState } = reviewSlice.actions;
export default reviewSlice.reducer;
