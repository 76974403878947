import DashboardLayout from "@/layouts/dashboard-layout";
import { useParams } from "react-router-dom";
import { teachers } from "@/mock/teachers";
import { useMemo } from "react";
import "./styles.scss";
import { TeacherCard } from "./teachers-card";
import { TeachersCalendar } from "./teachers-calendar";
import { MoreDetails } from "./more-details";

export function TeacherPage() {
  const params = useParams();
  const teacher_id = params.teacher_id;
  const teacher = useMemo(
    () => teachers.find((teacher) => teacher.id === teacher_id),
    [teacher_id]
  );
  if (!teacher) return <></>;
  return (
    <DashboardLayout>
      <div className="teachers_profile--container">
        <TeacherCard teacher={teacher} />
        <TeachersCalendar />
        <MoreDetails teacher={teacher} />
      </div>
    </DashboardLayout>
  );
}
