import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";
import { GET } from "../../../services/api-request";
import { UpcomingBooking } from "@/types/schedules";

interface UpcomingScheduleState {
  data: UpcomingBooking[] | null;
  loading: boolean;
  error: string | null;
}

const initialState: UpcomingScheduleState = {
  data: null,
  loading: false,
  error: null,
};

export const upcomingScheduleDataFetch = createAsyncThunk<
  UpcomingBooking[],
  string,
  { rejectValue: string }
>("upcomingSchedule", async (token, { rejectWithValue }) => {
  try {
    const { response } = await GET(
      `/bookings?upcoming=true&limit=10&offset=0`,
      token
    );

    
    const bookings = response.data.result?.theBookings || [];
    return bookings.sort(
      (a: UpcomingBooking, b: UpcomingBooking) =>
        new Date(b.time.start).getTime() - new Date(a.time.start).getTime()
    );
  } catch (err: any) {
    return rejectWithValue(err.response?.data?.message || "Unknown error");
  }
});

const upcomingScheduleDataSlice = createSlice({
  name: "upcomingSchedule",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(upcomingScheduleDataFetch.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        upcomingScheduleDataFetch.fulfilled,
        (state, action: PayloadAction<UpcomingBooking[]>) => {
          state.data = action.payload;
          state.loading = false;
          state.error = null;
        }
      )
      .addCase(upcomingScheduleDataFetch.rejected, (state, action) => {
        state.error = action.payload || "An error occurred.";
        state.loading = false;
      });
  },
});

export default upcomingScheduleDataSlice.reducer;
