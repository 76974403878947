import ReactCalendar, { type CalendarProps } from "react-calendar";
import "react-calendar/dist/Calendar.css";
import "./styles.scss";
import Icon from "../Icon";

/*
  This is the Calendar Component. It is responsive (set width my setting
  a width or max-width on the container), customizable too (by targetting relevant css classes). Check the
  react-calendar npm docs for a detailed list of all props
 */

export default function Calendar({ value, onChange, ...props }: CalendarProps) {
  const PrevLabel = <Icon name="caret_left" />;
  const NextLabel = <Icon name="caret_right_bold" color="white" />;

  const formatShortWeekday = (locale: string | undefined, date: Date) =>
    ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"][date.getDay()];

  return (
    <ReactCalendar
      value={value}
      onChange={onChange}
      prevLabel={PrevLabel}
      nextLabel={NextLabel}
      prev2Label={null}
      next2Label={null}
      formatShortWeekday={formatShortWeekday}
      {...props}
    />
  );
}
