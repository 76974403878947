import Icon from "../Icon";
import "./styles.scss";

export default function Avatar() {
  return (
    <div className="avatar_container">
      <img src="" alt="" />
      <div className="camera-icon">
        <Icon name="camera" />
      </div>
    </div>
  );
}
