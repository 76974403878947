import "./styles.scss";
import TeacherCard from "./TeacherCard";
import DashboardLayout from "@/layouts/dashboard-layout";
import { useQuery } from "@tanstack/react-query";
import { getTeachers } from "@/services/teachers/get";

export default function TeachersPage() {
  const { data } = useQuery({
    queryKey: ["teachers"],
    queryFn: getTeachers,
  });
  const teachers = data ?? [];

  return (
    <DashboardLayout>
      <div className="teachers-container">
        <div className="teachers-left">
          {teachers.map((teacher) => (
            <TeacherCard teacher={teacher} />
          ))}
        </div>
        <div className="teachers-right"></div>
      </div>
    </DashboardLayout>
  );
}
