import { Teacher } from "@/types/teacher";
import "./styles.scss";
import { Button, Icon } from "@/components";
import truncateString from "@/utils/truncateString";
import { useNavigate } from "react-router-dom";

export default function TeacherCard({ teacher }: { teacher: Teacher }) {
  const navigate = useNavigate();
  const handleCardClick = () => navigate(`/dashboard/teachers/${teacher.id}`);
  return (
    <div className="teacher_card--container">
      <div className="main">
        <div className="profile">
          <div className="avatar-container">
            <img src={teacher.avatar} alt="avatar" />
          </div>
          <div className="profile-right">
            <h1 onClick={handleCardClick}>
              {teacher.firstname} {teacher.lastname}
            </h1>
            <h3>{teacher.role}</h3>
            <div className="interests">
              {teacher.interests.map((interest) => (
                <div className="interest" key={interest.id}>
                  {interest.value}
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="languages-container">
          <div className="icon-container">
            <Icon name="school" />
          </div>
          <div className="languages">
            {teacher.languages.map((language) => (
              <div className="language">
                <span className="language-name">{language.name}</span>
                <span className="language-proficiency">
                  {language.proficiency}
                </span>
              </div>
            ))}
          </div>
        </div>
        <p className="bio">{truncateString(teacher.bio, 150)}</p>
      </div>
      <div className="bottom">
        <div className="price-container">
          <span className="title">Lessons from</span>
          <span className="price">USD 20</span>
        </div>
        <div className="btn-containers">
          <Button variant="neutral" className="send-btn">
            Book
          </Button>
          <Button variant="neutral" className="msg-btn">
            Message
          </Button>
        </div>
      </div>
    </div>
  );
}
