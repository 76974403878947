import axios from "axios";

const controller = new AbortController();

export const request = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  signal: controller.signal,
  // maxBodyLength: Infinity,
});

// request.interceptors.request.use(
//   async (response) => {
//     return response;
//   },
//   async (error) => {
//     return error;
//   }
// );

// request.interceptors.response.use(
//   async (response) => {
//     return response;
//   },
//   async (error) => {
//     if (error?.response?.status === 401 && !originalRequest._retry) {
//       originalRequest._retry = true;

//       if () {
//         try {

//         } catch (refreshError) {}
//       }
//     }

//     return Promise.reject(error);
//   }
// );
