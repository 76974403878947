import type { Activity } from "@/types/activity";
import { SelectWhatYouHearQuestion } from "./SelectCorrectImage";
import { FillTheGapQuestion } from "./FillTheGapQuestion";
import { TapWhatYouHearQuestion } from "./TapWhatYouHear";
import { CrossWordMatchQuestion } from "./CrossWordMatch";
import { SingleWordMatchQuestion } from "./SingleWordMatch";

type QuestionType = Activity["lessons"][number];

export const Question = ({
  question,
  handleNextQuestion,
}: {
  question: QuestionType;
  handleNextQuestion: () => void;
}) => {
  if (question.activityType === "SelectCorrectImageActivity") {
    return (
      <SelectWhatYouHearQuestion
        question={question}
        handleNextQuestion={handleNextQuestion}
      />
    );
  }
  if (question.activityType === "FillTheGapActivity") {
    return (
      <FillTheGapQuestion
        question={question}
        handleNextQuestion={handleNextQuestion}
      />
    );
  }

  if (question.activityType === "TapInWhatYouHear") {
    return (
      <TapWhatYouHearQuestion
        question={question}
        handleNextQuestion={handleNextQuestion}
      />
    );
  }

  if (question.activityType === "CrossWordActivity") {
    return (
      <CrossWordMatchQuestion
        question={question}
        handleNextQuestion={handleNextQuestion}
      />
    );
  }

  if (question.activityType === "MatchTheCorrectWord") {
    return (
      <SingleWordMatchQuestion
        question={question}
        handleNextQuestion={handleNextQuestion}
      />
    );
  }
  return <>Unknown question type</>;
};
