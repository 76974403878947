import "@/styles/global.scss";
import "./App.css";
import { Root } from "@/routes/Root";
import { Providers } from "./contexts/providers";
import { Provider } from "react-redux";
import { store } from "./redux/store";

function App() {
  return (
    <Provider store={store}>
      <Providers>
        <Root />
      </Providers>
    </Provider>
  );
}

export default App;
