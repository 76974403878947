import { Button, SearchInput } from "@/components";
import "./styles.scss";

interface Props<T extends { id: string; value: string }> {
  title: string;
  list: T[];
  selected_items: T[];
  onChange: (value: T[]) => void;
}

export default function EditListType<T extends { id: string; value: string }>({
  title,
  list,
  selected_items,
  onChange,
}: Props<T>) {
  const onClick = (clickedItem: T) => {
    const isSelected = !!selected_items.find(
      (item) => item.id === clickedItem.id
    );

    if (!isSelected) {
      onChange([...selected_items, clickedItem]);
    } else {
      const newList = selected_items.filter(
        (item) => item.id !== clickedItem.id
      );

      onChange(newList);
    }
  };
  return (
    <div className="list-edit--container">
      <h1 className="heading">{title}</h1>
      <SearchInput />
      <div className="list">
        {list.map((item, index) => (
          <Button
            variant="neutral"
            className="list-item"
            onClick={() => onClick(item)}
            data-selected={String(
              !!selected_items.find((i) => item.id === i.id)
            )}
            key={index}
          >
            {item.value}
          </Button>
        ))}
      </div>
    </div>
  );
}
